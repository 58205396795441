<template>
  <div class="ac2" style="width: 100%">
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b3.jpg" alt="" />
    </div>

    <div class="container">
      <!-- 容器 -->
      <div class="a2-con">
        <h2>北京久久创联网络科技有限公司</h2>
        <h4>Beijing Jiujiu Chuanglian Network Technology Co., Ltd</h4>
        <div class="a2-box">

          <div class="l">
            <p>
              北京久久创联网络科技有限公司成立于2019年09月17日，注册地位于北京市房山区拱辰街道月华大街1号A8-1757
            </p>

            <p>
              经营范围包括技术开发、技术转让、技术推广、技术服务、技术咨询（中介除外）；软件开发；应用软件服务；计算机系统服务；企业形象策划；设计、制作、代理、发布广告；市场调查；软件咨询、企业管理咨询（中介除外）；组织文化艺术交流活动（演出除外）；经营电信业务；网络文化经营；从事互联网文化活动。（市场主体依法自主选择经营项目，开展经营活动；经营电信业务、网络文化经营、从事互联网文化活动以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
            </p>
          </div>
          <div class="r">
            <img src="/imgs/usinfo.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "北京久久创联网络科技有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/index2.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.splice(0, 3);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {

  // margin-top: 65px;
  .banner {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      // height: 300px;
      height: 100%;
    }
  }

  .container {

    // 容器
    .a2-con {
      margin: 40px 0;

      h2 {
        font-size: 28px;
        color: #333;
      }

      h4 {
        font-size: 21px;
        color: #888;
      }

      .a2-box {
        width: 100%;
        height: 450px;
        @include flex();

        .l {
          width: 60%;
          height: 100%;
          // background-color: #ccc;
          border-bottom: 2px dashed #ccc;
          padding: 30px;
          box-sizing: border-box;

          p {
            width: 100%;
            font-size: 14px;
            line-height: 45px;
            text-indent: 30px;
          }
        }

        .r {
          width: 40%;
          height: 100%;

          // margin-left: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}</style>

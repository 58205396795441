<template>
  <div class="index">
    <div class="banner">
      <img src="/imgs/b3.jpg" alt="" />
    </div>

    <div class="container">
      <!-- 关于我们 -->
      <div class="us-con">
        <!-- 左侧 -->
        <div class="us-l">
          <img src="/imgs/us-1.png" alt="" />
        </div>
        <!-- 右侧 -->
        <div class="us-r">
          <h2>联系我们 <i>/</i><span>contact us</span></h2>
          <h4>北京网视在线科技有限公司</h4>

          <p>
            公司地址：北京市北京经济技术开发区(通州)东石东二路4号院7号楼5层531-1
          </p>
          <p>公司电话：010-86399489</p>
          <p>公司邮箱：475342231@qq.com</p>
        </div>
      </div>

      <div class="us-dt">
        <!-- <img src="/imgs/dt.png" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mdata: [],
      data2: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    this.init2();
    document.title = "北京网视在线科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.mdata = data.splice(0, 6);
      });
    },
    init2() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.data2 = data.splice(0, 10);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      // alert("请登录付费2元后可浏览！！！~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  margin-top: 65px;
  .banner {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // 关于我们
    .us-con {
      width: 100%;
      height: 300px;
      // background-color: pink;
      margin: 80px 0;
      @include flex();
      // 左侧
      .us-l {
        width: 40%;
        height: 100%;
        // background-color: #ff6600;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 右侧
      .us-r {
        width: 70%;
        height: 100%;
        // background-color: #c60023;
        margin-left: 30px;
        padding: 30px;
        box-sizing: border-box;
        border-bottom: 1px dashed #ccc;
        background: url("/imgs/us-2.png") no-repeat bottom;
        h2 {
          font-size: 26px;
          color: #333;
          span {
            color: $colorZ;
            // margin-left: 5px;
            font-size: 16px;
          }
          i {
            margin: 0 5px;
          }
        }

        h4 {
          font-size: 18px;
          color: #888;
          margin: 10px 0 40px;
        }

        p {
          font-size: 16px;
          // line-height: 40px;
          // text-indent: 30px;
          margin: 30px 0;
        }
      }
    }

    .us-dt {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
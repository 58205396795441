<template>
  <div class="n-header">
    <!-- 上层 -->
    <div class="n-s">
      <div class="container">
        <h2>欢迎访问北京久久创联网络科技有限公司~</h2>
        <!-- 登录注册 -->
        <div class="n-login">
          <!-- 注册 -->
          <!-- <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
            登录 <i>/</i> 立即注册
          </span> -->
          <span @click="goLogin()" v-show="this.LoggedShow"> 登录 </span>
          <i v-show="this.LoggedShow">/</i>
          <span @click="goRegister()" v-show="this.LoggedShow"> 立即注册 </span>
          <!-- 用户名 -->
          <span
            class="n-info"
            v-show="!this.LoggedShow"
            v-text="this.nv_user.Username"
            ><i class="iconfont icon-denglu"></i
          ></span>
          <!-- 退出登录 -->
          <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </span>
        </div>
      </div>
    </div>

    <!-- 下层 -->
    <div class="n-x">
      <div class="container">
        <!-- logo -->
        <div class="n-logo">
          <a href="/">
            <!-- <img src="/imgs/logo.png" alt="" /> -->

            <h2>北京久久创联网络科技有限公司</h2>
          </a>
        </div>
        <!-- 导航 -->
        <div class="n-list">
          <a href="/">网站首页</a>
          <a href="/#/ac1">行业资讯</a>
          <a href="/#/info">公司简介</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      this.$router.push("/");
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-header {

  // 上层
  .n-s {
    width: 100%;
    height: 30px;
    background: #e6e6e6;

    .container {
      height: 100%;
      @include flex();

      // 左侧 登录注册
      .n-login {
        span {
          font-size: 12px;
          color: #333;
          cursor: pointer;
          margin: 0 5px;

          i {
            margin: 0 5px;
          }
        }
      }

      // 右侧文字
      h2 {
        font-size: 14px;
        color: #888;
        font-weight: 400;
      }
    }
  }

  // 下层
  .n-x {
    width: 100%;
    height: 65px;
    margin-top: 15px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    box-sizing: border-box;

    .container {
      height: 100%;
      @include flex();

      // logo
      .n-logo {
        width: 370px;

        // height: 65px;
        h2 {
          color: $colorZ;
          font-size: 18px;
          cursor: pointer;
        }
      }

      // 导航
      .n-list {
        a {
          // font-size: 14px;
          color: #333;
          border: 1px solid #fafafa;
          border-radius: 20px;
          margin: 0 15px;
          display: inline-block;
          width: 120px;
          height: 30px;
          font-size: 12px;
          text-align: center;
          line-height: 30px;
          // transition: all 0.8;
          transition: all 0.3s;
          border-color: $colorZ;
          background: $colorZ;
          color: #fff;

          &:hover {
            // color: $colorZ;
            border-color: $colorZ;
            border-radius: 0;
          }
        }
      }
    }
  }
}</style>
<template>
  <div class="index">
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>

    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 关于我们区块 -->
      <div class="us">
        <!-- 标题 -->
        <div class="y-title">
          <h2>ABOUT US</h2>
          <div class="t-info">
            <div class="blink"></div>
            <span>关于我们</span>
            <div class="blink"></div>
          </div>
        </div>

        <!-- 容器 -->
        <div class="us-con">
          <!-- 右侧 -->
          <div class="us-r">
            <h2>北京久久创联网络科技有限公司</h2>
            <p>
              北京久久创联网络科技有限公司成立于2019年09月17日，注册地位于北京市房山区拱辰街道月华大街1号A8-1757
            </p>

            <p>
              经营范围包括技术开发、技术转让、技术推广、技术服务、技术咨询（中介除外）；软件开发；应用软件服务；计算机系统服务；企业形象策划；设计、制作、代理、发布广告；市场调查；软件咨询、企业管理咨询（中介除外）；组织文化艺术交流活动（演出除外）；经营电信业务；网络文化经营；从事互联网文化活动。（市场主体依法自主选择经营项目，开展经营活动；经营电信业务、网络文化经营、从事互联网文化活动以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
            </p>
          </div>

          <!-- 左侧 -->
          <div class="us-l">
            <img src="/imgs/info.jpg" alt="" />
          </div>
        </div>
      </div>

      <!-- 资讯区块 -->
      <div class="info">
        <!-- 标题 -->
        <div class="y-title">
          <h2>News and information</h2>
          <div class="t-info">
            <div class="blink"></div>
            <span>收费资讯</span>
            <div class="blink"></div>
          </div>
        </div>

        <!-- 容器 -->
        <div class="info-con">
          <!-- 右侧 -->
          <div class="info-r">
            <!-- 循环项 -->
            <div
              class="info-item"
              v-for="(item, index) in this.mAc"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <!-- 左侧 -->
              <div class="item-title">
                <i class=""></i>
                {{ item.title }}
              </div>
              <!-- 右侧 -->
              <div class="item-time">{{ item.time }}</div>
            </div>
          </div>
          <!-- 左侧 -->
          <div  class="info-l">
            <!-- 图片 -->
            <img src="/imgs/ct1.jpg" alt="" />
            <!-- 标题 -->
            <div class="info-title">从“马蜂窝数据事件”谈软件开发</div>
            <!-- 简介 -->
            <p>
              在设计软件系统时，领域知识往往是最重要的，而领域知识的一大部分就在于识别领域中的各种约束。这些约束很难由产品经理巨细靡遗地穷举出来，而必须由参与开发的所有人达成共识，在系统里实现它。但是无论如何，这些约束都是必不可少的，没有它们，系统就很可能出现各种稀奇古怪的现象...
            </p>
          </div>
        </div>
      </div>
    </div>

    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付：2元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import navigation from "./../components/navigation";
import Modal from "./../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "北京久久创联网络科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac.json").then((res) => {
        const data = res.data;
        this.mAc = data.slice(0, 9);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      alert("资讯每条2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  // margin-top: 70px;
  //  banner
  .banner {
    width: 100%;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    // 关于我们区块
    .us {
      // 标题
      .y-title {
        width: 100%;
        text-align: center;
        margin: 30px auto;
        margin-top: 60px;
        h2 {
          font-size: 24px;
          font-weight: 400px;
        }
        .t-info {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 16px;
            color: #888;
            margin: 0 10px;
          }
          .blink {
            width: 60px;
            height: 2px;
            background-color: $colorZ;
          }
        }
      }

      //   主容器
      .us-con {
        height: 350px;
        @include flex();
        //   左侧
        .us-l {
          width: 50%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 右侧
        .us-r {
          width: 50%;
          height: 100%;
          background-color: #fff;
          padding: 30px;
          box-sizing: border-box;
          h2 {
            font-size: 21px;
            font-weight: 400;
            margin-bottom: 30px;
          }
          p {
            font-size: 12px;
            color: #333;
            line-height: 29px;
            // margin: 10px 0;
            margin-bottom: 10px;
            text-indent: 30px;
          }
        }
      }
    }

    // 资讯
    .info {
      margin: 60px auto;
      // 标题
      .y-title {
        width: 100%;
        text-align: center;
        margin: 30px auto;
        margin-top: 60px;
        h2 {
          font-size: 24px;
          font-weight: 400px;
        }
        .t-info {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 16px;
            color: #888;
            margin: 0 10px;
          }
          .blink {
            width: 60px;
            height: 2px;
            background-color: $colorZ;
          }
        }
      }
      //   容器
      .info-con {
        width: 100%;
        height: 450px;
        @include flex();
        // 左侧
        .info-l {
          width: 50%;
          height: 100%;
          background: #fff;
          padding: 30px;
          box-sizing: border-box;
          text-align: center;
          //   图片
          img {
            width: 100%;
            margin: 0 auto;
            height: 200px;
          }
          // 标题
          .info-title {
            text-align: left;
            color: #333;
            font-size: 16px;
            margin: 30px 0;
          }

          //   简介
          p {
            font-size: 14px;
            color: #888;
            text-align: left;
          }
        }
        // 右侧
        .info-r {
          width: 50%;
          height: 100%;
          background-color: #fff;
          padding: 20px;
          box-sizing: border-box;
          //   循环项
          .info-item {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #888;
            margin-bottom: 15px;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              color: $colorZ;
            }
            // 左侧
            .item-title {
              width: 400px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              i {
                width: 10px;
                height: 10px;
                background-color: $colorZ;
                border-radius: 50%;
                margin-right: 5px;
                display: inline-block;
              }
            }
            // 右侧
            .item-time {
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="n-footer">
    <div class="container">
      <p>
        <a href="/">网站首页</a>
      </p>

      <p>2024 ©北京久久创联网络科技有限公司</p>

      <p>公司邮箱：13701223044@163.com</p>
      <p>公司地址：北京市房山区拱辰街道月华大街1号A8-1757</p>
      <p>
        <img src="/fufeizixun/imgs/g2.png" alt="" />
        <a target="_blink" href="https://beian.miit.gov.cn/#/Integrated/index">备案：京ICP备2021008733 </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",
  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  // height: 400px;
  background-color: #ededed;

  .container {
    height: 100%;
    padding: 7px 0;
    box-sizing: border-box;

    p {
      margin: 15px 0;
      font-size: 12px;
      color: #777;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      a {
        color: #777;
      }

      span {
        color: #777;
        margin: 0 10px;
      }
    }
  }
}</style>
